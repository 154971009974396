<div class="wrapper">
  <nav id="sidebar" class="sidebar js-sidebar">
    <div class="sidebar-content js-simplebar">
      <div class="row">
        <div class="col-1"></div>
        <div class="col-3 text-center my-auto">
          <img
            src="../assets/img/icon.png"
            class="w-100 m-2"
            alt=""
            srcset=""
          />
        </div>
        <div class="col-7 my-auto">
          <a class="sidebar-brand m-2 ml-0 p-0" href="/">
            <span class="text-left">Promogram</span>
          </a>
        </div>
      </div>

      <ul class="sidebar-nav">
        <li class="sidebar-header">Início</li>

        <li class="sidebar-item active">
          <a class="sidebar-link" (click)="toggleSidebar()" href="" [routerLink]="'/'">
            <i class="align-middle" data-feather="sliders"></i>
            <span class="align-middle">Dashboard</span>
          </a>
        </li>

        <li class="sidebar-item">
          <a class="sidebar-link" (click)="toggleSidebar()" href="" [routerLink]="'/posts'">
            <i class="align-middle" data-feather="check-square"></i>
            <span class="align-middle">Novo Post</span>
          </a>
        </li>

        <!-- <li class="sidebar-item">
          <a class="sidebar-link" href="pages-sign-up.html">
            <i class="align-middle" data-feather="send"></i>
            <span class="align-middle">Meus Canais</span>
          </a>
        </li>

        <li class="sidebar-item">
          <a class="sidebar-link" href="pages-sign-in.html">
            <i class="align-middle" data-feather="grid"></i>
            <span class="align-middle">Integração</span>
          </a>
        </li>

        <li class="sidebar-header">Minha Conta</li>

        <li class="sidebar-item">
          <a class="sidebar-link" href="ui-buttons.html">
            <i class="align-middle" data-feather="user"></i>
            <span class="align-middle">Perfil</span>
          </a>
        </li>

        <li class="sidebar-item">
          <a class="sidebar-link" href="ui-forms.html">
            <i class="align-middle" data-feather="sliders"></i>
            <span class="align-middle">Configurações</span>
          </a>
        </li>

        <li class="sidebar-item">
          <a class="sidebar-link" href="ui-forms.html">
            <i class="align-middle" data-feather="award"></i>
            <span class="align-middle">Tutoriais</span>
          </a>
        </li>

        <li class="sidebar-item">
          <a class="sidebar-link" href="ui-forms.html">
            <i class="align-middle" data-feather="edit"></i>
            <span class="align-middle">Suporte Técnico</span>
          </a>
        </li>

        <li class="sidebar-item">
          <a class="sidebar-link" href="ui-cards.html">
            <i class="align-middle" data-feather="log-out"></i>
            <span class="align-middle">Sair</span>
          </a>
        </li> -->
      </ul>
    </div>
  </nav>

  <div class="main">
    <app-sidebar2></app-sidebar2>
    <div class="content">
      <app-main></app-main>
    </div>

    <app-footer></app-footer>
  </div>
</div>
