import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';

@Injectable({
  providedIn: 'root',
})
export class ProdutoService {
  constructor(private http: HttpClient) {}

  getHeader() {
    const token = localStorage.getItem('token');
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer 82a164c1836b70b3086b8eb1927c3971e4e98991',
    });
    const requestOptions = { headers: header };
    return requestOptions;
  }

  async getProductUrl(url: any) {
    let links = {
      url,
    };
    console.log(links);
    const result = await this.http
      .post<any>('https://meta.vipserver.com.br/v2/', links)
      .toPromise();
    return result;
  }

  async getShortLink(url: any, title: any) {
    const data = {
      group_id: 'Bm3lkG4BDHl',
      domain: 'bit.ly',
      long_url: url,
      title: title
    };

    const result = await this.http
      .post<any>('https://api-ssl.bitly.com/v4/bitlinks', data, this.getHeader())
      .toPromise();
    return result;
  }

  async enviaTelegram(produto: any) {

    //const result = await this.http.post<any>('http://localhost/telegram/', produto, this.getHeader()).toPromise();

    const result = await this.http
      .post<any>('https://meta.vipserver.com.br/telegram/', produto, this.getHeader())
      .toPromise();
    return result;
  }
}
