<div id="pre-bootstrap" *ngIf="spinner">
    <div class="messaging">
      <div class="spinner-border m-5" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <h3 class="mt-3"> Cadastrar promoção </h3>
        <div class="row mt-5">
          <div class="col-12">

            <!-- Busca Informações do Link -->
            <form #form="ngForm" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col-12">
                  <label class="form-label">URL do Produto</label>
                </div>
                <div class="col-10">
                  <div class="mb-3">
                    <input
                      type="search"
                      name="url"
                      [(ngModel)]="product.url"
                      class="form-control"
                      value=""
                      placeholder="Ex: https://meusite.com/produto/maquina-de-lavar"
                    />
                  </div>
                  <div class="mb-2">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="ok"
                        id="flexCheckChecked"
                        checked
                        name="bitly"
                        [(ngModel)]="product.bitly"
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        Gerar Bit.ly
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="ok"
                        id="previewMobile"
                        checked
                        name="previewMobile"
                        [(ngModel)]="previewMobile"
                      />
                      <label class="form-check-label" for="previewMobile">
                        Visualizar Prévia
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <button class="btn btn-success">OK</button>
                </div>
              </div>
            </form>

            <!-- Dados Adicionais do Anúncio -->
            <form #form="ngForm" *ngIf="previewShow" (ngSubmit)="onEnvia()">
              <div class="mb-3">
                <label>Escolha o canal da publicação</label>
                <select class="form-control" name="canal" [(ngModel)]="product.canal">
                  <option value="viplojaoficial">@viplojaoficial</option>
                  <option selected value="-696854308">Grupo VipLoja</option>
                </select>
              </div>
              <div class="mb-3">
                <label>Escolha o emoji da publicação</label>
                <select
                  name="emoji"
                  [(ngModel)]="product.emoji"
                  class="form-control"
                >
                  <option value="🔥🔥🔥">🔥🔥🔥</option>
                  <option value="😎😎😎">😎😎😎</option>
                  <option value="😍😍😍">😍😍😍</option>
                  <option value="📱📱📱">📱📱📱</option>
                  <option value="🚨🚨🚨">🚨🚨🚨</option>
                  <option value="👏🏻👏🏻👏🏻">👏🏻👏🏻👏🏻</option>
                  <option value="🤩🤩🤩">🤩🤩🤩</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">Link da Imagem</label>
                <input type="text" name="imagem" (change)="geraImagem()" class="form-control" [(ngModel)]="product.image" id="">
              </div>
              <div class="mb-3">
                <label class="form-label">Descritivo do Post</label>
                <textarea
                  [(ngModel)]="product.campain"
                  placeholder="Ex: Mega promoção em IPhones"
                  name="campain"
                  class="form-control"
                  cols="10"
                  rows="3"
                ></textarea>
              </div>
              <div class="md-3">
                <label for="">Preço de:</label>
                <input
                  *ngIf="semPreco"
                  type="text"
                  name="price_old"
                  [(ngModel)]="product.price_old"
                  class="form-control"
                  value=""
                  placeholder=""
                  (change)="precoOld(product.price_old)"
                />
              </div>
              <div class="md-3">
                <label for="">Preço por:</label>
                <input
                  *ngIf="semPreco"
                  type="text"
                  name="price_new"
                  [(ngModel)]="product.price_new"
                  class="form-control"
                  value=""
                  placeholder=""
                  (change)="precoPor(product.price_new)"
                  required
                />
              </div>
  
              <div class="mb-3 mt-3">
                <div class="d-grid gap-2">
                  <button class="btn btn-success">Gerar Post</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="phone" *ngIf="previewMobileDiv">
          <div class="frame-container">
          <div class="conteudo">
            <div class="foto">
              <figure *ngIf="temFoto" class="figure">
                <img
                [src]="objeto.image"
                  class="figure-img img-fluid rounded"
                  alt="..."
                />
              </figure>
              <div class="legenda" *ngIf="previewShow">
                <p>{{ product.emoji }}</p>
                <p [innerHtml]="product.campain"></p>
                <p [innerHtml]="product.title"></p>
                <p *ngIf="precoDe">de R$ {{ product.price_old }}</p>
                <p>
                  <span *ngIf="precoDe">por </span> R$ {{ product.price_new }}
                </p>
                <p>Acesse: {{ product.shortLink }}</p>
                <p>Link pra entrar no grupo de ofertas:</p>
                <p>https://t.me/{{ product.canal }}</p>
              </div>
            </div>
          </div>
      </div>
      </div>
    </div>
  </div>
  
  <div
    class="modal fade"
    id="modal"
    #modal
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  