import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProdutoService } from 'src/app/services/produto.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css'],
})
export class PostsComponent implements OnInit {
  constructor(private produtoService: ProdutoService, private router: Router) {
    this.router = router;
  }

  

  title = 'telegram';
  temFoto = false;
  spinner = false;
  previewShow = false;
  previewMobile = false;
  previewMobileDiv = false;
  semPreco = false;
  precoDe = false;

  product = {
    title: '',
    product_price_amount: '',
    url: '',
    shortLink: '',
    image: '',
    price_old: '',
    price_new: '',
    emoji: '',
    campain: '',
    bitly: true,
    canal: '',
  };

  objeto = {
    image: '',
    foto: '',
    channel: '',
    message: '',
  };

  loading(action: any) {
    this.spinner = action;
  }

  async onSubmit() {
    try {
      this.loading(true);
      if (this.previewMobile) {
        this.previewMobileDiv = true;
      }
      const product = await this.produtoService.getProductUrl(this.product.url);
      if (product.tags.length > 0) {
        this.previewShow = true;
        this.product = product.tags[0];
        this.loading(false);
        this.product.bitly = true;
        if (this.product.bitly) {
          this.geraBitly();
        } else {
          this.product.shortLink = this.product.url;
        }
        if (this.product.image !== null && this.product.image !== undefined) {
          this.objeto.image = this.product.image;
          this.objeto.foto = 'true';
          this.temFoto = true;
        } else {
        }
        if (
          this.product.product_price_amount !== '' &&
          this.product.product_price_amount !== null
        ) {
          this.product.price_new = this.product.product_price_amount;
          this.semPreco = true;
        } else {
          this.product.price_new = this.product.product_price_amount;
        }
      } else {
        console.log(product);
      }
    } catch (error) {}
  }

  async geraBitly() {
    const bitly = await this.produtoService.getShortLink(
      this.product.url,
      this.product.title
    ).then((res) => {
      this.product.shortLink = res.link;
    }, (err) => {
        console.error(err);
    });
    
  }

  async onEnvia() {
    if (this.product.price_new === undefined) {
      alert('Informe o preço do produto');
    } else {
      this.loading(true);

      if (this.product.emoji !== '' && this.product.campain !== undefined) {
        this.objeto.message = this.product.emoji + '\n\n';
      }

      if (
        this.product.campain !== '' &&
        this.product.campain !== undefined &&
        this.product.campain !== null
      ) {
        this.objeto.message += this.product.campain + '\n\n';
      }
      this.objeto.message += this.product.title + '\n\n';
      if (this.precoDe) {
        this.objeto.message += 'de R$ ' + this.product.price_old + '\npor ';
      }
      this.objeto.message += 'R$ ' + this.product.price_new + '\n\n';

      this.objeto.message += 'Acesse: ' + this.product.shortLink + '\n\n';

      this.objeto.message += 'Ajude a fortalecer o grupo, compartilhe\n';
      this.objeto.message += 'https://t.me/' + this.product.canal + ' \n\n';

      this.objeto.channel = this.product.canal;

      console.log(this.objeto);

      const telegram = await this.produtoService.enviaTelegram(this.objeto);
      this.loading(false);
      console.log(telegram);
      // this.redireciona();
    }
  }

  redireciona() {
    console.log('aqui');
    document.location.href = '/posts';
  }

  geraImagem() {
    console.log('aqui');
    this.objeto.image = this.product.image;
    this.objeto.foto = 'true';
    this.temFoto = true;
  }

  copy() {
    var range = document.createRange();
    range.selectNode(document.getElementById('copia'));
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand('copy');
    window.getSelection().removeAllRanges(); // to deselect
  }
  precoPor(preco: any) {
    console.log(preco);
    if (preco !== '') {
      this.product.price_new = preco;
    }
  }

  precoOld(preco: any) {
    if (preco !== '') {
      this.precoDe = true;
      this.product.price_old = preco;
    }
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    this.objeto.image = 'data:image/jpg;base64,' + btoa(e.target.result);
    this.objeto.foto = 'true';
    this.temFoto = true;
  }
  ngOnInit(): void {
    
  }
}
