import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isMobile = false;
  constructor() {}

  toggleSidebar() {
    if (this.isMobile) {
      const sidebarElement = document.getElementsByClassName('js-sidebar')[0];
      sidebarElement.classList.toggle('collapsed');
      sidebarElement.addEventListener('transitionend', () => {
        window.dispatchEvent(new Event('resize'));
      });
    }
  }

  ngOnInit(): void {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    }
  }
}
