import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './home/header/header.component';
import { SidebarComponent } from './home/sidebar/sidebar.component';
import { FooterComponent } from './home/footer/footer.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { UsersComponent } from './components/users/users.component';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { PostsComponent } from './components/posts/posts.component';
import { Sidebar2Component } from './home/sidebar2/sidebar2.component';
import { MainComponent } from './home/main/main.component';
//{provide: LocationStrategy, useClass: HashLocationStrategy}
@NgModule({
  declarations: [AppComponent, HeaderComponent, SidebarComponent, FooterComponent, DashboardComponent, UsersComponent, IntegrationsComponent, PostsComponent, Sidebar2Component, MainComponent],
  imports: [BrowserModule, AppRoutingModule, FormsModule, HttpClientModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
