import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntegrationsComponent } from './components/integrations/integrations.component';
import { PostsComponent } from './components/posts/posts.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { MainComponent } from './home/main/main.component';

const routes: Routes = [{
  path: '', 
  component: MainComponent,
  children: [
    { path: '', component: DashboardComponent },
    { path: 'posts', component: PostsComponent },
    { path: 'integration', component: IntegrationsComponent },
  ]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
